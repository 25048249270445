import { Typography } from '@coconut-software/ui';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { PAGES } from '../constants';
import { SelectionContext } from '../contexts/SelectionContext';
import { SettingsContext } from '../contexts/SettingsContext';
import { MOBILE, ViewModeContext } from '../contexts/ViewModeContext';
import Tracker from '../helpers/Tracker';
import Button from './Button';
import Modal from './Modal';
import TrackPageView from './TrackPageView';

const FastRouteModal = ({
  handleDismiss,
  open,
  handleServiceSelect,
  data: { message, url },
}) => {
  const { firstStep } = useContext(SettingsContext);
  const [selections] = useContext(SelectionContext);
  const viewMode = useContext(ViewModeContext);

  const handleSkipTheWaitClick = () => {
    Tracker.view(
      PAGES.FASTER_ROUTE_LINK,
      Tracker.getTrackingData(PAGES.FASTER_ROUTE_LINK, firstStep, selections),
    );
    window.open(url, '_parent', 'noopener=false, noreferrer=false');
  };

  const buttonContent = (
    <>
      {url ? (
        <Button
          classes={viewMode === MOBILE ? { override: 'mb-1' } : {}}
          fullWidth={viewMode === MOBILE}
          onClick={handleSkipTheWaitClick}
          type="button"
        >
          <FormattedMessage id="FastRoute.skip_the_wait" />
        </Button>
      ) : null}
      <Button
        fullWidth={viewMode === MOBILE}
        onClick={handleServiceSelect}
        type="button"
        variant="secondary"
      >
        <FormattedMessage id="FastRoute.book_anyway" />
      </Button>
    </>
  );

  const buttons =
    viewMode === MOBILE ? (
      <div className="w-full">{buttonContent}</div>
    ) : (
      buttonContent
    );

  return (
    <Modal
      buttons={buttons}
      header={<FormattedMessage id="FastRoute.title" />}
      isOpen={open}
      onRequestClose={handleDismiss}
      testId="fast-route"
    >
      <>
        <TrackPageView identifier={PAGES.FASTER_ROUTE} />
        {message ? (
          <Typography variant="subtitle">
            <span dangerouslySetInnerHTML={{ __html: message }} />
          </Typography>
        ) : null}
      </>
    </Modal>
  );
};

FastRouteModal.propTypes = {
  handleDismiss: PropTypes.func.isRequired,
  open: PropTypes.bool,
  data: PropTypes.shape({
    message: PropTypes.string,
    url: PropTypes.string,
  }),
};

FastRouteModal.defaultProps = {
  data: PropTypes.shape({
    message: null,
    url: null,
  }),
  open: false,
};

export default FastRouteModal;
